$(function() {
  setTimeout(function() {
    $('#flashMessage').fadeOut();
  }, 3500);

  if($('input.form-time').length) {
    $('input.form-time').numeric({
      decimal: false,
      negative: false
    });
  }
});

function dateToDMY(date) {
  var day = parseInt(date.getDate());
  var month = parseInt(date.getMonth())+1;
  var year = parseInt(date.getFullYear());

  if(day < 10) day = '0'+day;
  if(month < 10) month = '0'+month;

  return day + '-' + month + '-' + year;
}